import {createSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    executePost, executeGet, executeDelete, executeUpload, executePut,
} from "../Api";
import axios from "../api/axiosApi";
import {
    onCheckEmpty, eventUrl, updateIntakeFormsImmutable, updateImmutable,
} from "../utils";

import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER_LOADING,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FETCH_CURRENT_USER_SUCCESS,
    FETCH_CURRENT_USER_ERROR,
    FETCH_CURRENT_USER_LOADING,
    FETCH_USER_ACCOUNTS_SUCCESS,
    FETCH_USER_ACCOUNTS_ERROR,
    FETCH_USER_ACCOUNTS_LOADING,
    FETCH_GETALL_DATA_LOADING,
    FETCH_GETALL_DATA_SUCCESS,
    FETCH_GETALL_DATA_ERROR,
    FETCH_ADMIN_USER_LOADING,
    FETCH_ADMIN_USER_SUCCESS,
    FETCH_ADMIN_USER_ERROR,
    FORGOT_EMAIL_SUCCESS,
    FORGOT_EMAIL_LOADING,
    UPDATE_PASSWORD_LOADING,
    UPDATE_PASSWORD_SUCCESS,
    CHECK_VALIDHASH_LOADING,
    CHECK_VALIDHASH_SUCCESS,
    CHECK_VALIDHASH_ERROR,
    UPDATE_EMAIL_LOADING,
    UPDATE_EMAIL_ERROR,
    CHECK_VALID_EMAIL_LOADING,
    CHECK_VALID_EMAIL_SUCCESS,
    CHECK_VALID_EMAIL_ERROR,
    UPDATE_EMAIL_SUCCESS,
    FETCH_TRANSACTION_DATA_LOADING,
    FETCH_TRANSACTION_DATA_SUCCESS,
    FETCH_TRANSACTION_DATA_ERROR,
    GENERATE_ACCESS_CODE_LOADING,
    GENERATE_ACCESS_CODE_SUCCESS,
    GENERATE_ACCESS_CODE_ERROR,
    VERIFY_ACCESS_CODE_LOADING,
    VERIFY_ACCESS_CODE_SUCCESS,
    VERIFY_ACCESS_CODE_ERROR,
    DELETE_USERDATA_LOADING,
    DELETE_USERDATA_SUCCESS,
    DELETE_USERDATA_ERROR,
    LOGIN_ACCESSCODE_LOADING,
    LOGIN_ACCESSCODE_SUCCESS,
    LOGIN_ACCESSCODE_ERROR,
    VERIFY_LOGIN_ACCESSCODE_LOADING,
    VERIFY_LOGIN_ACCESSCODE_SUCCESS,
    VERIFY_LOGIN_ACCESSCODE_ERROR,
    UPDATEPASSWORD_LINK_ERROR,
    UPDATEPASSWORD_LINK_LOADING,
    UPDATEPASSWORD_LINK_SUCCESS,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    UPLOAD_FILE_LOADING,
    ADMIN_LOGIN_ACCESSCODE_LOADING,
    ADMIN_LOGIN_ACCESSCODE_SUCCESS,
    ADMIN_LOGIN_ACCESSCODE_ERROR,
    FETCH_CURRENT_ADMIN_LOADING,
    FETCH_CURRENT_ADMIN_SUCCESS,
    FETCH_CURRENT_ADMIN_ERROR,
    ADMIN_CHECK_VALIDHASH_LOADING,
    ADMIN_CHECK_VALIDHASH_SUCCESS,
    ADMIN_CHECK_VALIDHASH_ERROR,
    ADMIN_UPDATE_PASSWORD_LOADING,
    ADMIN_UPDATE_PASSWORD_SUCCESS,
    ADMIN_UPDATE_PASSWORD_ERROR,
    ADMIN_PASSWORD_LOADING,
    ADMIN_PASSWORD_SUCCESS,
    ADMIN_PASSWORD_ERROR,
    PAYUSER_SUCCESS,
    PAYUSER_ERROR,
    PAYUSER_LOADING,
    PAYUSER_CLEAR,
    SEND_SMS_LOADING,
    SEND_SMS_SUCCESS,
    SEND_SMS_ERROR,
    UPDATE_ADMIN_DATA_LOADING,
    UPDATE_ADMIN_DATA_SUCCESS,
    UPDATE_ADMIN_DATA_ERROR,
    DELETE_UPLOADED_LOADING,
    DELETE_UPLOADED_SUCCESS,
    DELETE_UPLOADED_ERROR,
    UPLOAD_LOGO_FILE_LOADING,
    UPLOAD_LOGO_FILE_SUCCESS,
    UPLOAD_LOGO_FILE_ERROR,
    SAVE_TRANSACTION_SUCCESS,
    SAVE_TRANSACTION_ERROR,
    SAVE_TRANSACTION_LOADING,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_LOADING,
    DELETE_CARD_ERROR,
    OFFICE_CONTACT_LOADING,
    OFFICE_CONTACT_SUCCESS,
    OFFICE_CONTACT_ERROR,
    UPDATE_USER_DATA_LOADING,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_ERROR,
    RESENDCODE_LOADING,
    RESENDCODE_SUCCESS,
    RESENDCODE_ERROR,
    LOGIN_ADMIN_SUCCESS,
    LOGIN_ADMIN_ERROR,
    LOGIN_ADMIN_LOADING,
    USER_SENDCODE_LOADING,
    USER_SENDCODE_SUCCESS,
    USER_SENDCODE_ERROR,
    GET_EMBEDDED_SIGN_SUCCESS,
    GET_EMBEDDED_SIGN_LOADING,
    GET_EMBEDDED_SIGN_ERROR,
    GET_MESSAGE_LOADING,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_ERROR,
    SAVE_HELLOSIGN_TEMPLATE_SUCCESS,
    SAVE_HELLOSIGN_TEMPLATE_LOADING,
    SAVE_HELLOSIGN_TEMPLATE_ERROR,
    GET_HELLOSIGN_TEMPLATE_SUCCESS,
    GET_HELLOSIGN_TEMPLATE_LOADING,
    GET_HELLOSIGN_TEMPLATE_ERROR,
    USER_CONTACT_LOADING,
    USER_CONTACT_SUCCESS,
    USER_CONTACT_ERROR,
    DELETE_MESSAGE_USER_LOADING,
    SHOW_USER_MESSAGE_ERROR,
    SHOW_USER_MESSAGE_LOADING,
    DELETE_MESSAGE_LOADING,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_ERROR,
    UPLOAD_USERFORM_LOADING,
    UPLOAD_USERFORM_SUCCESS,
    UPLOAD_USERFORM_ERROR,
    SET_USERFORMS,
    USERFORM_DELETE_LOADING,
    USERFORM_DELETE_SUCCESS,
    USERFORM_DELETE_ERROR,
    TRANSACTION_GET_SUCCESS,
    TRANSACTION_GET_ERROR,
    TRANSACTION_GET_LOADING,
    SEND_INTAKE_SUCCESS,
    SEND_INTAKE_LOADING,
    SEND_INTAKE_ERROR,
    FETCH_CURRENT_MESSAGES_LOADING,
    FETCH_CURRENT_MESSAGES_SUCCESS,
    FETCH_CURRENT_MESSAGES_ERROR,
    FETCH_URL_LOADING,
    FETCH_URL_ERROR,
    FETCH_URL_SUCCESS,
    NOTIFICATION_INTAKEFORM_ERROR,
    NOTIFICATION_INTAKEFORM_SUCCESS,
    NOTIFICATION_INTAKEFORM_LOADING,
    GET_USERFORM_SUCCESS,
    GET_USERFORM_LOADING,
    GET_USERFORM_ERROR,
    SEND_MESSAGE_SUCCESS_NOTIFY,
    MESSAGE_TO_ADMIN_ERROR,
    MESSAGE_TO_ADMIN_LOADING,
    MESSAGE_TO_ADMIN_SUCCESS,
    SET_TEMP_INTAKEFORM_ID,
    ACCOUNT_LOGOUT,
    TRANSACTION_IS_VOIDABLE_LOADING,
    TRANSACTION_IS_VOIDABLE_SUCCESS,
    TRANSACTION_IS_VOIDABLE_ERROR,
    TRANSACTION_REFUND_LOADING,
    TRANSACTION_REFUND_SUCCESS,
    TRANSACTION_REFUND_ERROR,
    UPDATE_INTAKE_FORM_TYPE_SUCCESS,
    UPDATE_INTAKE_FORM_TYPE_LOADING,
    UPDATE_INTAKE_FORM_TYPE_ERROR,
} from "../constants";

export const loginAct = (data) => {
    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        executePost("users/authenticate", data)
            .then((res) => {
                const {data} = res || {};
                const logoUrl = data && data.logoUrl;
                if (res.statusText === "OK") {
                    //setAuthHeader(res.data.token);
                    if (res && res.data) {
                        const {payerEmail, id} = res.data;
                        const userData = {
                            payerEmail, id, sendBy: "phone",
                        };
                        dispatch(getLoginAuthenticateCode(userData));
                    }
                } else {
                    dispatch({type: LOGIN_ERROR});
                    // toast.error("Incorrect login credentials");
                }

                dispatch({type: UPLOAD_LOGO_FILE_SUCCESS, payload: {logoUrl}});
                return dispatch({type: LOGIN_SUCCESS, payload: res.data});
            })
            .catch((err) => {
                console.log("err line j206werf", err);
                // toast.error("Incorrect login credentials");
                return dispatch({type: LOGIN_ERROR});
            });
    };
};

export const adminLoginAct = (data) => {
    return (dispatch) => {
        dispatch({type: LOGIN_ADMIN_LOADING});
        executePost("SosCustomers/adminAuthenticate", data)
            .then((res) => {
                const {data} = res || {};
                const logoUrl = data && data.logoUrl;
                if (res.statusText === "OK") {
                    const {token} = res.data;
                    //setAuthHeader(token);

                    if (res && res.data) {
                        const {adminEmail, _id} = res.data;
                        const adminuserData = {
                            adminEmail, _id, sendBy: "email",
                        };
                        dispatch(getAdminLoginAuthenticateCode(adminuserData));
                    }
                } else {
                    dispatch({type: LOGIN_ADMIN_ERROR});
                }
                dispatch({type: UPLOAD_LOGO_FILE_SUCCESS, payload: {logoUrl}});
                dispatch({type: LOGIN_ADMIN_SUCCESS, payload: res.data});
                return;
            })
            .catch((err) => {
                console.log("err #pqofn3j2", err);
                toast.error(err?.response?.data?.message || "Connection unsuccessful. Please check your internet connection.");
                return dispatch({type: LOGIN_ADMIN_ERROR});
            });
    };
};

export const verificationAccessCode = (data, navigate, isSetAuth = false) => {
    const baseUrl = `users/verifyCodeWithHash`;

    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        executePost(baseUrl, data)
            .then((res) => {
                const {data} = res || {};
                const {user} = data || {};
                if (user.token) {
                    user["type"] = "user";
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("token", user.token);
                    // if (isSetAuth) {
                    //   //setAuthHeader(user.token);
                    // }
                    navigate(`${eventUrl(user.subdomain)}home`);
                    return dispatch({type: LOGIN_SUCCESS, payload: {data: user}});
                } else {
                    navigate(`${eventUrl(user.subdomain)}login`);
                }
                ;
            })
            .catch((err) => {
                console.log("err line a206asdf", err);
                // toast.error(err?.response?.data?.message || "Something went wrong."));
                return dispatch({type: LOGIN_ERROR});
            });
    };
};


export const registerAccessCode = (data, navigate, isSetAuth = false) => {
    const baseUrl = `users/registerVerifyCode`;

    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        executePost(baseUrl, data)
            .then((res) => {
                const {data} = res || {};
                const {user} = data || {};
                if (user.token) {
                    user["type"] = "user";
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("token", user.token);
                    // if (isSetAuth) {
                    //   const token = user?.token;
                    //   setAuthHeader(token);
                    // };
                    navigate(`${eventUrl(user.subdomain)}home`);
                    return dispatch({type: LOGIN_SUCCESS, payload: {data: user}});
                } else {
                    navigate(`${eventUrl(user.subdomain)}login`);
                }
            })
            .catch((err) => {
                console.log("err line p22p06ew", err);
                // toast.error(err?.response?.data?.message || "Something went wrong."));
                return dispatch({type: LOGIN_ERROR});
            });
    };
};

export const adminVerificationAccessCode = (data, navigate, isSetAuth = false) => {
    const baseUrl = `SosCustomers/adminVerificationCode`;

    return (dispatch) => {
        executePost(baseUrl, data)
            .then((res) => {
                const {data} = res || {};
                const {user} = data || {};
                if (user.token) {
                    user["type"] = "admin";
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("token", user.token);
                    // if (isSetAuth) {
                    //   setAuthHeader(user.token);
                    // }
                    dispatch({type: LOGIN_SUCCESS, payload: data});
                    dispatch({type: FETCH_ADMIN_USER_SUCCESS, payload: user});
                    dispatch(getUserforms(user.subdomain));
                    navigate(`${eventUrl(user.subdomain)}adminDashboard`);
                    return;
                } else {
                    navigate(`${eventUrl(user.subdomain)}admin`);
                }
            })
            .catch((err) => {
                console.log("err #206werfm", err);
                toast.error(err?.response?.data?.message || "Something went wrong.");
                return dispatch({type: LOGIN_ERROR});
            });
    };
};

export const sendCodeByTextMsg = (data) => (dispatch) => {
    dispatch({type: SEND_SMS_LOADING});
    return executePost(`users/send-auth-text`, data)
        .then((res) => {
            const {data} = res || {};
            const {message} = data || "";

            if (res.statusText === "OK") {
                toast.success(message);
                return dispatch({type: SEND_SMS_SUCCESS, data});
            } else {
                toast.error(`${message || "Account not found"}`);
                return dispatch({type: SEND_SMS_ERROR});
            }
        })
        .catch((err) => {
            dispatch({type: SEND_SMS_ERROR});
            console.log("err uo325nbs", err);
            toast.error(`${(err?.response?.data?.message) || "Account not found"}`);
        });
};
export const onLoginWithToken = (tokenHash, subdomain, navigate) => {
    return (dispatch) => {
        executePost(`users/loginWithHash`, {tokenHash, subdomain})
            .then((res) => {
                const {data} = res || {};
                const {token} = data || {};
                if (token) {
                    //setAuthHeader(token);
                    if (onCheckEmpty(data && data.password)) {
                        localStorage.setItem('state', JSON.stringify({
                            existingUser: true, tokenHash: data.tokenHash, userEmail: data.payerEmail,
                        }));
                        navigate(`${eventUrl(data.subdomain)}login`);
                    } else {
                        localStorage.setItem('state', JSON.stringify({
                            willCreateUser: true, tokenHash: data.tokenHash,
                        }));
                        navigate(`${eventUrl(data.subdomain)}register`);
                    }
                } else {
                    localStorage.setItem('state', JSON.stringify({
                        existingUser: false, tokenHash: "",
                    }));
                    navigate(`${eventUrl(data.subdomain)}login`);
                }
            })
            .catch((err) => {
                console.log("err p105naauq", err);
                localStorage.setItem('state', JSON.stringify({
                    existingUser: false, tokenHash: "",
                }));
                navigate(`${eventUrl(subdomain)}login`);
                return dispatch({type: LOGIN_ERROR});
            });
    };
};

export const registerAct = (data) => {
    return (dispatch) => {
        dispatch({type: REGISTER_LOADING});
        executePost("users/register", data)
            .then((res) => {
                let payload = {};
                const {data} = res || {};
                const {token} = data || {};
                const logoUrl = data && data.user && data.user.logoUrl;
                if (res.statusText === "OK") {
                    // setAuthHeader(token);
                    localStorage.setItem("token", token);
                    payload = {
                        data: JSON.parse(res.config.data), status: {success: true, msg: ""},
                    };
                } else {
                    payload = {
                        data: res.data, status: {success: false, msg: res.statusText},
                    };
                }
                dispatch({type: UPLOAD_LOGO_FILE_SUCCESS, payload: logoUrl});
                dispatch({type: LOGIN_SUCCESS, payload});
                return dispatch({type: REGISTER_SUCCESS, payload});
            })
            .catch((err) => {
                console.log("err line pnwje13z", err);
                return dispatch({type: REGISTER_ERROR});
            });
    };
};

export const sentAccessCode = (userData, navigate) => {
    return (dispatch) => {
        dispatch({type: USER_SENDCODE_LOADING});
        executePost(`users/sentCode`, userData)
            .then(() => {
                localStorage.setItem('state', JSON.stringify({
                    createdUser: {...userData, tokenHash: userData.tokenHash},
                }));
                navigate(`${eventUrl(userData.subdomain)}accessCode`);
                return dispatch({type: USER_SENDCODE_SUCCESS});
            })
            .catch((err) => {
                console.log("err 283hfuwh", err);
                dispatch({type: USER_SENDCODE_ERROR});
            });
    };
};

export const adminSentAccessCode = (userData, navigate, isAdmin = false) => {
    const baseUrl = isAdmin && window.location.pathname.includes("/adminRegistration", -18) ? `SosCustomers/adminRegisterAuthenticate` : `SosCustomers/sentCode`;

    return (dispatch) => {
        executePost(baseUrl, userData)
            .then((res) => {
                const {data} = res || {};
                const {sosCustomer} = data || {};
                const logoUrl = sosCustomer && sosCustomer.logoUrl;
                localStorage.setItem('state', JSON.stringify({
                    createdUser: {...userData, tokenHash: sosCustomer.tokenHash},
                }));
                navigate(`${eventUrl(sosCustomer.subdomain)}adminAccessCode`, {
                    replace: true,
                });
                dispatch({type: UPLOAD_LOGO_FILE_SUCCESS, payload: {logoUrl}});
                return dispatch({type: ADMIN_LOGIN_ACCESSCODE_SUCCESS});
            })
            .catch((err) => {
                console.log("err 10fhznvb", err);
                toast.error((err?.response?.data?.message) || "Something went wrong.");
            });
    };
};

export const createPassword = (data, navigate) => {
    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        executePost(`users/createPassword`, data)
            .then((res) => {
                const {data} = res || {};
                const {user} = data || {};
                localStorage.setItem('state', JSON.stringify({willCreateUser: false, tokenHash: ""}));
                navigate(`${eventUrl(user.subdomain)}accessCode`);
                return dispatch({type: LOGIN_SUCCESS, payload: user});
            })
            .catch((err) => {
                console.log("err 70fhzqvb", err);
                // toast.error(err?.response?.data?.message || "Something went wrong.");
            });
    };
};
export const forgotEmail = (data) => {
    return (dispatch) => {
        dispatch({type: FORGOT_EMAIL_LOADING});
        executePost(`users/sendForgotEmail`, data)
            .then((res) => {
                if (res.statusText === "OK") {
                    return dispatch({type: FORGOT_EMAIL_SUCCESS, payload: res});
                }
            })
            .catch((err) => {
                console.log("err qo3i5nbs", err);
            });
    };
};
export const updatePassword = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_PASSWORD_LOADING});
        executePost(`users/updatePassword`, data)
            .then((res) => {
                if (res.statusText === "OK") {
                    dispatch({type: UPDATE_PASSWORD_SUCCESS, payload: res});
                    return;
                }
            })
            .catch((err) => {
                console.log("err q2i5nbs9", err);
            });
    };
};

export const getValidHash = (tokenHash) => {
    return (dispatch) => {
        dispatch({type: CHECK_VALIDHASH_LOADING});
        executeGet(`users/checkValidHash${tokenHash}`)
            .then((res) => {
                const {data} = res || {};

                return dispatch({type: CHECK_VALIDHASH_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err wpeov4fj", err);
                return dispatch({type: CHECK_VALIDHASH_ERROR});
            });
    };
};


export const validateJwtTokenExpiry = (subdomain) => {

    try {
        return (dispatch) => {
            executeGet(`users/validateJwtTokenExpiry/${subdomain}`)
                .then((res) => {

                })
                .catch((err) => {
                    console.log("err qo3i5nbs", err);
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("payerUser");
                    dispatch({type: ACCOUNT_LOGOUT});
                });
        };

    } catch (err) {
        console.log("err woei3o2i", err);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("payerUser");
    }
};


export const getValidHashAdmin = (tokenHash) => {
    return (dispatch) => {
        dispatch({type: ADMIN_CHECK_VALIDHASH_LOADING});
        executeGet(`SosCustomers/checkValidHashAdmin${tokenHash}`)
            .then(() => {
                return dispatch({type: ADMIN_CHECK_VALIDHASH_SUCCESS});
            })
            .catch((err) => {
                console.log("err wpeowhei", err);
                return dispatch({type: ADMIN_CHECK_VALIDHASH_ERROR});
            });
    };
};
export const updateEmailRequest = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_EMAIL_LOADING});
        executePost(`users/sendUpdateEmail`, data)
            .then((res) => {
                if (res.statusText === "OK") {
                    return dispatch({type: UPDATE_EMAIL_SUCCESS, payload: res});
                }
            })
            .catch((err) => {
                console.log("err 2p3i4bfu", err);
                dispatch({type: UPDATE_EMAIL_ERROR});
            });
    };
};
export const getCheckValidEmailHash = (tokenValid) => {
    return (dispatch) => {
        dispatch({type: CHECK_VALID_EMAIL_LOADING});
        executeGet(`users/checkValidEmailHash${tokenValid}`)
            .then((res) => {
                const {data} = res || {};

                return dispatch({type: CHECK_VALID_EMAIL_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err 20fhmqvb", err);
                return dispatch({type: CHECK_VALID_EMAIL_ERROR});
            });
    };
};
export const updateEmail = (data, navigate) => {
    return (dispatch) => {
        dispatch({type: UPDATE_EMAIL_LOADING});
        executePost(`users/updateEmail`, data)
            .then((res) => {
                if (res.statusText === "OK") {
                    localStorage.setItem('state', JSON.stringify({willCreateUser: false, tokenHash: ""}));
                    navigate({});
                    return dispatch({type: UPDATE_EMAIL_SUCCESS, payload: res});
                }
            })
            .catch((err) => {
                console.log("err 1ofhzqvm", err);
                // toast.error(err?.response?.data?.message || "Something went wrong.");
                dispatch({type: UPDATE_EMAIL_ERROR});
            });
    };
};
export const getUserData = (subdomain) => {
    return (dispatch) => {
        dispatch({type: FETCH_CURRENT_USER_LOADING});
        executeGet(`users/current/${subdomain}`, {})
            .then((res) => {
                const {data} = res || {};
                data["type"] = "user";
                localStorage.setItem("user", JSON.stringify(data));
                return dispatch({type: FETCH_CURRENT_USER_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err g0fhzqvb", err);
                return dispatch({type: FETCH_CURRENT_USER_ERROR});
            });
    };
};

export const getAllUsers = (subdomain) => {
    return (dispatch) => {
        dispatch({type: FETCH_GETALL_DATA_LOADING});
        executeGet(`users/${subdomain}`, {})
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: FETCH_GETALL_DATA_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err d0ghzqvb", err);
                return dispatch({type: FETCH_GETALL_DATA_ERROR});
            });
    };
};

export const getAdminUserData = (subdomain) => {
    return (dispatch) => {
        dispatch({type: FETCH_ADMIN_USER_LOADING});
        executeGet(`SosCustomers/adminUser/${subdomain}`)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: FETCH_ADMIN_USER_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err wie2zqvb", err);
                return dispatch({type: FETCH_ADMIN_USER_ERROR});
            });
    };
};

export const getAdminUserByHash = (tokenHash, subdomain, navigate) => {
    return (dispatch) => {
        dispatch({type: FETCH_ADMIN_USER_LOADING});
        executePost(`SosCustomers/adminUserByHash`, {subdomain, tokenHash})
            .then((res) => {
                const {data} = res || {};
                const {tokenHash, password} = data || {};
                if (tokenHash) {
                    if (password.length < 12) {
                        localStorage.setItem('state', JSON.stringify({
                            willCreateUser: true, tokenHash: tokenHash, isAdmin: true
                        }));
                        navigate(`${eventUrl(subdomain)}adminRegistration`, {
                            // pathname: `${eventUrl(subdomain)}adminRegistration`, //AdminLogin
                            replace: true
                        });
                    } else {
                        localStorage.setItem('state', JSON.stringify({
                            existingUser: true, tokenHash: tokenHash, isAdmin: true,
                        }));
                        navigate(`${eventUrl(subdomain)}admin`);
                    }
                } else {
                    localStorage.setItem('state', JSON.stringify({existingUser: false, tokenHash: ""}));
                    navigate(`${eventUrl(subdomain)}admin`);
                }
                return dispatch({type: FETCH_ADMIN_USER_SUCCESS, payload: data});
            })
            .catch((err) => {
                if (err) {
                    toast.error(`${err?.response?.data?.message}`);
                    navigate(`${eventUrl(subdomain)}admin`);
                }
                return dispatch({type: FETCH_ADMIN_USER_ERROR});
            });
    };
};

export const getAccountDetails = (subdomain) => {
    return (dispatch) => {
        dispatch({type: FETCH_USER_ACCOUNTS_LOADING});
        executeGet(`users/accounts/${subdomain}`, {})
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: FETCH_USER_ACCOUNTS_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err pqo3zqvb", err);
                return dispatch({type: FETCH_USER_ACCOUNTS_ERROR});
            });
    };
};
export const saveGuestUser = (data) => {
    return () => {
        // dispatch({ type: GUESTUSER_LOADING });
        executePost("guestUser", data)
            .then(() => {
                // dispatch({ type: SAVE_GUESTUSER_SUCCESS, payload: data });
            })
            .catch((err) => {
                console.log("err pqo3zqvb", err);
                // return dispatch({ type: SAVE_GUESTUSER_ERROR });
            });
    };
};

export const getPaymentsToken = async (data) => {
    return executePost("payments/token", data)
        .then((res) => {
            const {data} = res || {};
            return data;
        })
        .catch((err) => {
            console.log("err pwo3zqvb", err);
            return err;
        });
};

export const transactionError = () => (dispatch) => {
    dispatch({type: SAVE_TRANSACTION_ERROR});
};

export const onTransactionLoading = () => (dispatch) => {
    dispatch({type: SAVE_TRANSACTION_LOADING});
};

export const savePaymentsDataAdmin = (data) => async (dispatch) => {
    return executePost("transaction", data)
        .then((res) => {
            const {data} = res || {};
            dispatch({type: SAVE_TRANSACTION_SUCCESS});
            return data;
        })
        .catch((err) => {
            console.log("err tyru3847", err);
            dispatch({type: SAVE_TRANSACTION_ERROR});
            return err;
        });
};
export const savePaymentsData = (data) => async (dispatch) => {
    return executePost("transaction", data)
        .then((res) => {
            const {data} = res || {};
            const {user} = data || {};
            localStorage.setItem("user", JSON.stringify(user));
            dispatch({type: FETCH_CURRENT_USER_SUCCESS, payload: user});
            dispatch({type: SAVE_TRANSACTION_SUCCESS});
            return data;
        })
        .catch((err) => {
            console.log("err pqk3zqvb", err);
            dispatch({type: SAVE_TRANSACTION_ERROR});
            return err;
        });
};

export const getTransactionData = (subdomain) => {
    return (dispatch) => {
        dispatch({type: FETCH_TRANSACTION_DATA_LOADING});
        executeGet(`Transaction/all/${subdomain}`, {})
            .then((res) => {
                const {data} = res || [];
                return dispatch({
                    type: FETCH_TRANSACTION_DATA_SUCCESS, payload: data || [],
                });
            })
            .catch((err) => {
                console.log("err pwo3ngj2", err);
                return dispatch({type: FETCH_TRANSACTION_DATA_ERROR});
            });
    };
};

export const logoutAct = (isAdmin = false, subdomain) => {
    return (dispatch, getState) => {
        const isAdminUserSuccess = getState().auth.isAdminUserSuccess;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("payerUser");
        dispatch({type: ACCOUNT_LOGOUT});
        //setAuthHeader("");
        if (isAdmin) {
            window.location.replace(`${eventUrl(subdomain)}admin`);
        } else {
            window.location.replace(`${eventUrl(subdomain)}login`);
        }
    };
};

export const generateAccessCode = (data) => {
    return (dispatch) => {
        dispatch({type: GENERATE_ACCESS_CODE_LOADING});
        executePost("users/sentCode", data)
            .then(() => {
                return dispatch({type: GENERATE_ACCESS_CODE_SUCCESS});
            })
            .catch((err) => {
                console.log("err pwlensj3", err);
                return dispatch({type: GENERATE_ACCESS_CODE_ERROR});
            });
    };
};

export const verifyAccessCode = (data) => {
    return (dispatch) => {
        dispatch({type: VERIFY_ACCESS_CODE_LOADING});
        executePost("users/verifyCodeWithHash", data)
            .then((res) => {
                const {data} = res || {};
                const {user} = data || {};
                if (res) {
                    // setAuthHeader(user.token);
                    user["type"] = "user";
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("token", user.token);
                }
                return dispatch({type: VERIFY_ACCESS_CODE_SUCCESS});
            })
            .catch((err) => {
                console.log("err apqk3o29", err);
                return dispatch({type: VERIFY_ACCESS_CODE_ERROR});
            });
    };
};

export const getPayerUser = (data) => {
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        executePost("SosCustomers/payerUser", data)
            .then((res) => {
                const {data} = res || {};
                localStorage.setItem("payerUser", JSON.stringify(data.data));
                return dispatch({type: PAYUSER_SUCCESS, payload: data.data});
            })
            .catch((err) => {
                console.log("err 20183hfu", err);
                toast.error((err?.response?.data?.message) || "Something went wrong.");
                return dispatch({type: PAYUSER_ERROR});
            });
    };
};
export const reduxSetPayerUser = (data) => {
    return (dispatch) => {
        localStorage.setItem("payerUser", JSON.stringify(data));
        return dispatch({type: PAYUSER_SUCCESS, payload: data});
    };
};
export const clearPayerUser = () => {
    localStorage.removeItem("payerUser");
    return (dispatch) => {
        return dispatch({type: PAYUSER_CLEAR, payload: {}});
    };
};

export const getPayerUserByNonuniqueIdentifier = (data, setIsOpenPayerList, setPayerList, navigate, subdomain, returnOnly = false) => {
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        return executePost("SosCustomers/payerUserByNonuniqueIdentifier", data)
            .then((res) => {
                if (res.data) {
                    if (res.data.length === 1) {
                        const payUser = res.data[0];
                        dispatch({type: PAYUSER_LOADING});
                        if (!returnOnly) {
                            navigate(`${eventUrl(subdomain)}adminDashboard/payments`);
                        }
                        localStorage.setItem("payerUser", JSON.stringify(payUser));
                        dispatch({type: PAYUSER_SUCCESS, payload: payUser});
                        return payUser;
                    } else {
                        setPayerList(res.data);
                        setIsOpenPayerList(true);
                        return res.data;
                    }
                }
            })
            .catch((err) => {
                console.log("err 2p30fjvn", err);
                toast.error((err?.response?.data?.message) || "Something went wrong.");
                return dispatch({type: PAYUSER_ERROR});
            });
    };
};

export const getPayerUserForAdminLookup = (data, navigate, returnOnly = false) => {
    const {subdomain} = data || "";
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        return executePost("SosCustomers/payerUserForAdminLookup", data)
            .then((res) => {
                const {data} = res || {};
                const datadata = data && data.data;
                if (!returnOnly) {
                    navigate(`${eventUrl(subdomain)}adminDashboard/payments`);
                }
                localStorage.setItem("payerUser", JSON.stringify(datadata));
                dispatch({type: PAYUSER_SUCCESS, payload: datadata});
                if (res.data && res.data.data) {
                    return res.data;
                }
            })
            .catch((err) => {
                console.log("err ujhu02bg", err);
                toast.error((err?.response?.data?.message) || "Something went wrong.");
                return dispatch({type: PAYUSER_ERROR});
            });
    };
};

export const redirectPayUserForAdminLookup = (payUser, subdomain, navigate) => {
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        navigate(`${eventUrl(subdomain)}adminDashboard/payments`);
        localStorage.setItem("payerUser", JSON.stringify(payUser));
        dispatch({type: PAYUSER_SUCCESS, payload: payUser});
        return payUser
    };
};

export const getPayerUserById = (data) => {

    const {subdomain, id} = data || "";
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        return executeGet(`users/getById/${id}`, data)
            .then((res) => {
                const {data} = res || {};

                localStorage.setItem("payerUser", JSON.stringify(data));
                dispatch({type: PAYUSER_SUCCESS, payload: data});
                if (res.data) {
                    return res.data;
                }
            })
            .catch((err) => {
                console.log("err po39gyr3", err);
                toast.error((err?.response?.data?.message) || "Something went wrong.");
                return dispatch({type: PAYUSER_ERROR});
            });
    };
};


export const getLoginAuthenticateCode = (data) => {
    return (dispatch) => {
        dispatch({type: LOGIN_ACCESSCODE_LOADING});
        executePost("users/loginAuthenticate", data)
            .then(() => {
                return dispatch({
                    type: LOGIN_ACCESSCODE_SUCCESS, payload: "Code sent. Please check your email.",
                });
            })
            .catch((err) => {
                console.log("err 2p30rjeu", err);
                return dispatch({type: LOGIN_ACCESSCODE_ERROR});
            });
    };
};

export const verifyLoginAuthenticateCode = (data) => {
    return (dispatch) => {
        dispatch({type: VERIFY_LOGIN_ACCESSCODE_LOADING});
        executePost("users/verifyLoginAuthenticate", data)
            .then((res) => {
                const {user} = res.data || {};
                // setAuthHeader(user.token);
                user["type"] = "user";
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", user.token);
                return dispatch({
                    type: VERIFY_LOGIN_ACCESSCODE_SUCCESS, payload: user,
                });
            })
            .catch((err) => {
                console.log("err wpdhfueu", err);
                return dispatch({type: VERIFY_LOGIN_ACCESSCODE_ERROR});
            });
    };
};

export const adminUpdatePasswordLink = (data) => {
    return (dispatch) => {
        dispatch({type: ADMIN_UPDATE_PASSWORD_LOADING});
        return executePost(`SosCustomers/adminSentCode`, data)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: ADMIN_UPDATE_PASSWORD_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err 2p30rjfn", err);
                dispatch({type: ADMIN_UPDATE_PASSWORD_ERROR});
                return err;
            });
    };
};

export const updatePasswordAdmin = (data) => {
    return (dispatch) => {
        dispatch({type: ADMIN_PASSWORD_LOADING});
        executePost(`SosCustomers/updatePasswordAdmin`, data)
            .then((res) => {
                if (res.statusText === "OK") {
                    dispatch({type: ADMIN_PASSWORD_SUCCESS, payload: res});
                    return;
                }
            })
            .catch((err) => {
                console.log("err 203jvngj", err);
                dispatch({type: ADMIN_PASSWORD_ERROR});
                return err;
            });
    };
};
export const updateAdminData = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_ADMIN_DATA_LOADING});
        executePut(`SosCustomers/update`, data)
            .then((res) => {
                const {data} = res || {};
                toast.success((data && data.message) || "Settings saved successfully");
                const {sosCustomers} = data;
                return dispatch({
                    type: UPDATE_ADMIN_DATA_SUCCESS, payload: sosCustomers,
                });
            })
            .catch((err) => {
                console.log("err 203pfnrj", err);
                dispatch({type: UPDATE_ADMIN_DATA_ERROR});
                toast.error({message: "something went wrong while updating profile settings"});
                return err;
            });
    };
};

export const updatePayerUserData = (data) => {
    return (dispatch) => {
        dispatch({type: PAYUSER_LOADING});
        executePut(`users/update`, data)
            .then((res) => {
                localStorage.setItem("payerUser", JSON.stringify(data));
                return dispatch({type: PAYUSER_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err hu47381g", err);
                return dispatch({type: PAYUSER_ERROR});
                return err;
            });
    };
};

export const updateUserData = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATE_USER_DATA_LOADING});
        executePut(`users/update`, data)
            .then((res) => {
                const {response} = res || {};
                const {data} = response || {};
                return dispatch({type: UPDATE_USER_DATA_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err 203p5jhf", err);
                dispatch({type: UPDATE_USER_DATA_ERROR});
                return err;
            });
    };
};

export const deleteUserData = (id, subdomain) => {
    return (dispatch) => {
        dispatch({type: DELETE_USERDATA_LOADING});
        return executeDelete(`users/deleteUser/${subdomain}/${id}`)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: DELETE_USERDATA_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err wpekgjma", err);
                dispatch({type: DELETE_USERDATA_ERROR});
                return err;
            });
    };
};
export const markNotADuplicate = (id, subdomain) => {
    return (dispatch) => {
        // dispatch({type: DELETE_USERDATA_LOADING});
        return executePut(`users/markNotADuplicate/${subdomain}/${id}`)
            .then((res) => {
                const {data} = res || {};
                // return dispatch({type: DELETE_USERDATA_SUCCESS, payload: data});
                window.location.reload();
                return true;
            })
            .catch((err) => {
                console.log("err 2h3u2h3u", err);
                // dispatch({type: DELETE_USERDATA_ERROR});
                return err;
            });
    };
};

export const deleteCard = (subdomain, data) => (dispatch) => {
    dispatch({type: DELETE_CARD_LOADING});
    return executeDelete(`users/deleteCard/${subdomain}`, {data})
        .then((res) => {
            const {data} = res || {};

            return dispatch({type: DELETE_CARD_SUCCESS, payload: data});
        })
        .catch((err) => {
            console.log("err qpworjgn", err);
            dispatch({type: DELETE_CARD_ERROR});
            return err;
        });
};

export const updatePasswordLink = (data) => {
    return (dispatch) => {
        dispatch({type: UPDATEPASSWORD_LINK_LOADING});
        return executePost(`users/sendForgotEmail`, data)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: UPDATEPASSWORD_LINK_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err qpwkrmfn", err);
                dispatch({type: UPDATEPASSWORD_LINK_ERROR});
                return err;
            });
    };
};

export const getAdminLoginAuthenticateCode = (data) => {
    return (dispatch) => {
        dispatch({type: ADMIN_LOGIN_ACCESSCODE_LOADING});
        executePost("SosCustomers/adminloginAuthenticate", data)
            .then(() => {
                return dispatch({
                    type: ADMIN_LOGIN_ACCESSCODE_SUCCESS, payload: "Code sent. Please check your email.",
                });
            })
            .catch((err) => {
                console.log("err wpelgmgk", err);
                return dispatch({type: ADMIN_LOGIN_ACCESSCODE_ERROR});
            });
    };
};

export const verifyAdminLoginAuthenticateCode = (data) => {
    return (dispatch) => {
        dispatch({type: VERIFY_LOGIN_ACCESSCODE_LOADING});
        executePost("SosCustomers/adminverifyLoginAuthenticate", data)
            .then((res) => {
                const {user} = res.data || {};
                //setAuthHeader(user.token);
                user["type"] = "admin";
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", user.token);
                return dispatch({
                    type: VERIFY_LOGIN_ACCESSCODE_SUCCESS, payload: user,
                });
            })
            .catch((err) => {
                console.log("err wp3k2orn", err);
                return dispatch({type: VERIFY_LOGIN_ACCESSCODE_ERROR});
            });
    };
};

export const sendMesssageToAdmin = (data, subdomain) => (dispatch) => {
    dispatch({type: OFFICE_CONTACT_LOADING});
    return executePost(`message/sendMsg/${subdomain}`, data)
        .then((res) => {
            const {data} = res || {};
            const {message} = data;
            dispatch({type: OFFICE_CONTACT_SUCCESS, payload: message});
            return data;
        })
        .catch((err) => {
            console.log("err wpekti3j", err);
            dispatch({type: OFFICE_CONTACT_ERROR});
            return err;
        });
};

export const sendMesssageToAdminReply = (data, subdomain) => (dispatch) => {
    dispatch({type: MESSAGE_TO_ADMIN_LOADING});
    return executePost(`message/sendMsgReply/${subdomain}`, data)
        .then((res) => {
            const {data} = res || {};
            dispatch({type: MESSAGE_TO_ADMIN_SUCCESS});
            dispatch(getMessage(subdomain));
            return data;
        })
        .catch((err) => {
            console.log("err qpwotufh", err);
            dispatch({type: MESSAGE_TO_ADMIN_ERROR});
            return err;
        });
};

export const sendMesssageToUser = (data, subdomain) => (dispatch, getState) => {
    dispatch({type: USER_CONTACT_LOADING});
    const messages = getState().auth.messages;
    return executePost(`message/sendMsgToUser/${subdomain}`, data)
        .then((res) => {
            const {data} = res || {};
            const updatedMessages = updateImmutable(messages, data.message_reponse, "_id", "id", true);
            console.dir("updatedMessages #8s9s8392");
            console.dir(updatedMessages);
            console.trace();
            dispatch({type: USER_CONTACT_SUCCESS, payload: updatedMessages});
            return data;
        })
        .catch((err) => {
            console.log("err qpgldm38", err);
            dispatch({type: USER_CONTACT_ERROR});
            return err;
        });
};

export const getAdminData = (subdomain, _id) => {
    return (dispatch) => {
        dispatch({type: FETCH_CURRENT_ADMIN_LOADING});
        executeGet(`SosCustomers/current/${_id}/${subdomain}`)
            .then((res) => {
                const {data} = res || {};
                data["type"] = "admin";
                localStorage.setItem("user", JSON.stringify(data));
                return dispatch({type: FETCH_CURRENT_ADMIN_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err qpfkti48", err);
                return dispatch({type: FETCH_CURRENT_ADMIN_ERROR});
            });
    };
};

export const uploadMultipleFiles = (formData, id, isDocument, subdomain) => (dispatch) => {
    dispatch({type: UPLOAD_FILE_LOADING, payload: isDocument});
    return executeUpload(`SosCustomers/${id}/${isDocument}/${subdomain}/uploadmultiple`, formData)
        .then((res) => {
            const {data} = res || {};
            dispatch(getAdminUserData(subdomain));
            dispatch({type: UPLOAD_FILE_SUCCESS, payload: data});
            return data;
        })
        .catch((err) => {
            console.log("err qptj4839", err);
            dispatch({type: UPLOAD_FILE_ERROR});
            return err;
        });
};

export const uploadUserForms = (formData, userId, documentType, subdomain) => (dispatch) => {
    // documentType is expected to be "upload" or "embedded"
    // dispatch({ type: UPLOAD_FILE_LOADING });
    dispatch({type: UPLOAD_USERFORM_LOADING});
    return executeUpload(`userforms/adduploadeduserform/${subdomain}/${documentType}/${userId}`, formData)
        .then(() => {
            dispatch({type: UPLOAD_USERFORM_SUCCESS});
            return true;
        })
        .catch((err) => {
            console.log("err ghtu3927", err);
            dispatch({type: UPLOAD_USERFORM_ERROR});
            return err;
        });
};

export const uploadLogo = (formData, id, subdomain) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_LOGO_FILE_LOADING});
        return executeUpload(`SosCustomers/${id}/${subdomain}/uploadLogo`, formData)
            .then((res) => {
                const {data} = res || {};
                const logoUrl = data && data.user && data.user.logoUrl;
                const sosCustomer = data && data.user;
                return dispatch({
                    type: UPLOAD_LOGO_FILE_SUCCESS, payload: {logoUrl, sosCustomer},
                });
            })
            .catch((err) => {
                console.log("err tj4i3839", err);
                dispatch({type: UPLOAD_LOGO_FILE_ERROR});
                return err;
            });
    };
};

export const deleteUploadedDoc = (data) => {
    const {subdomain, type} = data;
    let url = "";
    if (type === "document") {
        url = "SosCustomers/removeDoc";
    } else if (type === "intakeform") {
        url = "SosCustomers/removeIntakeForm";
    } else {
        throw new Error("Failed to remove");
    }
    return (dispatch) => {
        dispatch({type: DELETE_UPLOADED_LOADING});
        return executeDelete(url, {data})
            .then((res) => {
                const {dataResponse} = res || {};
                if (data) {
                    dispatch(getAdminUserData(subdomain));
                    return dispatch({type: UPLOAD_FILE_SUCCESS, payload: dataResponse});
                }
            })
            .catch((err) => {
                console.log("err whti382o", err);
                dispatch({type: UPLOAD_FILE_ERROR});
                return err;
            });
    };
};

export const uploadIntakeForm = (formData, id) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_FILE_LOADING});
        return executeUpload(`users/${id}/uploadIntake`, formData)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: DELETE_UPLOADED_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err 20tu3839", err);
                dispatch({type: DELETE_UPLOADED_ERROR});
                return err;
            });
    };
};

export const downloadSignedForm = (subdomain, userFormId, docName, action) => {
    console.dir("action")
    console.dir(action)
    return (dispatch) => {
        dispatch({type: UPLOAD_FILE_LOADING});
        return axios({
            method: "get", url: `userforms/downloadUserForm/${subdomain}/${userFormId}`, responseType: "blob",
        })
            .then((resp) => {
                if (resp) {
                    const url = window.URL.createObjectURL(new Blob([resp.data],{type: 'application/pdf'}));
                    const link = document.createElement("a");
                    link.href = url;
                    if (action === "download") {
                        link.setAttribute("download", docName);
                    } else if (action === "previewInNewTab") {
                        link.setAttribute("target", "_blank");
                        link.setAttribute("rel", "noopener noreferrer");
                    }
                    document.body.appendChild(link);
                    link.click();
                }

                return resp;
            })
            .catch((err) => {
                console.log("err dneughwu", err);
                dispatch({type: UPLOAD_FILE_ERROR});
                return err;
            });
    };
};

export const downloadFile = (filename) => {
    const data = {filename};
    return (dispatch) => {
        dispatch({type: UPLOAD_FILE_LOADING});
        return axios({
            method: "post", url: `sosCustomers/download`, responseType: "blob", data,
        })
            .then((resp) => {
                if (resp) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                }
                return resp;
            })
            .catch((err) => {
                console.log("err w0gjru3i", err);
                dispatch({type: UPLOAD_FILE_ERROR});
                return err;
            });
    };
};

export const resendVerificationCode = (data) => {
    return (dispatch) => {
        dispatch({type: RESENDCODE_LOADING});
        return executePost(`users/resendCode`, data)
            .then((res) => {
                if (res) {
                    return dispatch({type: RESENDCODE_SUCCESS});
                }
            })
            .catch((err) => {
                console.log("err zpzkxock", err);
                dispatch({type: RESENDCODE_ERROR});
                return err;
            });
    };
};

export const addToUserforms = (userFormData, subdomain) => {
    return (dispatch) => {
        executePost(`userforms/addesigneduserform/${subdomain}`, userFormData)
            .then((res) => {
                if (res) {
                    setTimeout(() => {
                        localStorage.removeItem('helloSignCalled');
                        window.location.reload();
                    }, 500);
                    dispatch(getUserforms(subdomain));
                }
            })
            .catch((err) => {
                console.log("err zbcmxbcn", err);
                dispatch({type: GET_EMBEDDED_SIGN_ERROR});
                return err;
            });
        return true;
    };
};

export const getUserforms = (subdomain) => {
    return (dispatch) => {
        executeGet(`userforms/getuserforms/${subdomain}`)
            .then((res) => {
                if (res) {
                    const {data} = res && res.data;
                    const userForms = data;
                    dispatch({
                        type: SET_USERFORMS, payload: userForms,
                    });
                }
            })
            .catch((err) => {
                console.log("err woei2839", err);
                dispatch({type: GET_EMBEDDED_SIGN_ERROR});
                return err;
            });
        return true;
    };
};

export const createSignatureRequestAndGetUrl = (subdomain, template_id, payerEmail, payerName) => {
    return (dispatch) => {
        dispatch({type: GET_EMBEDDED_SIGN_LOADING});
        executeGet(`hellosign/createSignatureRequest/${subdomain}/${template_id}/${payerEmail}/${payerName}`)
            .then((res) => {
                if (res) {
                    const embeddedHelloSignUrl = res && res.data && res.data;
                    dispatch({
                        type: GET_EMBEDDED_SIGN_SUCCESS, payload: embeddedHelloSignUrl,
                    });
                }
            })
            .catch((err) => {
                console.log("err woejgjei", err);
                dispatch({type: GET_EMBEDDED_SIGN_ERROR});
                return err;
            });
        return true;
    };
};

export const saveAfterCreateTemplate = (subdomain, sosCustomerId, intakeformId, template_id) => {
    return (dispatch, getState) => {
        const adminUser = getState().auth.adminUser;
        dispatch({type: SAVE_HELLOSIGN_TEMPLATE_LOADING});
        return executePost(`hellosign/saveAfterCreateTemplate/${subdomain}`, {
            sosCustomerId, intakeformId, template_id,
        })
            .then((res) => {
                const {intakeform} = res.data || {};
                const intakeforms = updateIntakeFormsImmutable(adminUser.intakeforms, intakeform);
                adminUser.intakeforms = intakeforms;
                dispatch(getUserforms(subdomain));
                dispatch({type: FETCH_ADMIN_USER_SUCCESS, payload: adminUser});
                dispatch({type: SAVE_HELLOSIGN_TEMPLATE_SUCCESS});
                return true;
            })
            .catch((err) => {
                console.log("err qpwoqp39", err);
                dispatch({type: SAVE_HELLOSIGN_TEMPLATE_ERROR});
                return err;
            });
    };
};

export const setTempIntakeformId = (intakeformId) => {
    return (dispatch) => {
        dispatch({type: SET_TEMP_INTAKEFORM_ID, payload: intakeformId});
    };
};

export async function getHelloSignTemplate(dispatch, getState) {
    const adminUser = getState().auth.adminUser;
    const tempIntakeFormId = getState().auth.tempIntakeFormId;
    const sosCustomerId = adminUser._id.toString();
    dispatch({type: GET_HELLOSIGN_TEMPLATE_LOADING});
    const baseURL = process.env.REACT_APP_API;
    return await axios
        .get(`${baseURL}hellosign/getHelloSignTemplate/${adminUser.subdomain}/${sosCustomerId}/${tempIntakeFormId}`, {
            headers: {
                Authorization: `bearer ${localStorage.getItem("token")}`, "Content-Type": "application/json",
            },
        })
        .then(async (res) => {
            // return your data here...
            const template = res.data;
            const {edit_url} = template;
            localStorage.removeItem("intakeformId");
            const intakeform = adminUser.intakeforms.find((f) => f._id.toString() === tempIntakeFormId);
            intakeform.template = template;
            const intakeforms = await updateIntakeFormsImmutable(adminUser.intakeforms, intakeform);
            adminUser.intakeforms = intakeforms;
            dispatch({type: FETCH_ADMIN_USER_SUCCESS, payload: adminUser});
            dispatch({type: GET_HELLOSIGN_TEMPLATE_SUCCESS});
            return edit_url;
        })
        .catch((err) => {
            console.log("err ghfjghfj", err);
            dispatch({type: GET_HELLOSIGN_TEMPLATE_ERROR});
            return err;
        });
}

export const verifyAdminEmailChange = (tokenHash, subdomain, navigate, setIsEmailVerified) => {
    return () => {
        const baseUrl = `SosCustomers/verifyAdminEmailChange`;
        executePost(baseUrl, {tokenHash, subdomain})
            .then((res) => {
                const {status, data} = res || {};
                // const { message } = data || {};

                setIsEmailVerified(true);
                localStorage.setItem('state', JSON.stringify({
                    isEmailVerified: true,
                }));
                navigate(`${eventUrl(subdomain)}verifyAdminEmailChangeResult`, {
                    search: `?${createSearchParams({
                        success: "true"
                    })}`
                });
            })
            .catch((err) => {
                console.log("err #2039tuaa", err);
                setIsEmailVerified(false);
                localStorage.setItem('state', JSON.stringify({
                    isEmailVerified: false,
                }));
                navigate(`${eventUrl(subdomain)}verifyAdminEmailChangeResult`, {
                    search: `?${createSearchParams({
                        success: "false"
                    })}`
                });
                return false;
            });

    };
};

export const getMessage = (subdomain) => {
    return (dispatch) => {
        dispatch({type: GET_MESSAGE_LOADING});
        executeGet(`message/getMessage/${subdomain}`)
            .then((res) => {
                const {data} = res || {};
                return dispatch({type: GET_MESSAGE_SUCCESS, payload: data});
            })
            .catch((err) => {
                console.log("err wptueiru", err);
                return dispatch({type: GET_MESSAGE_ERROR});
            });
    };
};

export const userSoftDeleteMessage = (id) => (dispatch) => {
    const data = {id: id};

    dispatch({type: DELETE_MESSAGE_USER_LOADING});
    return executePost(`message/deleteMessage`, data)
        .then(() => {
            dispatch({type: DELETE_UPLOADED_SUCCESS});
            return true;
        })
        .catch((err) => {
            console.log("err woejghei", err);
            dispatch({type: DELETE_USERDATA_ERROR});
            return false;
        });
};

export const adminHardDeleteMessage = (id) => (dispatch) => {
    dispatch({type: DELETE_MESSAGE_LOADING});
    return executeDelete(`message/delete/${id}`)
        .then(() => {
            dispatch({type: DELETE_MESSAGE_SUCCESS});
            return true;
        })
        .catch((err) => {
            console.log("err wpeofjeo", err);
            dispatch({type: DELETE_MESSAGE_ERROR});
            return false;
        });
};

export const userMessageShow = (subdomain, userId) => (dispatch) => {
    dispatch({type: SHOW_USER_MESSAGE_LOADING});
    return executeGet(`message/userMessage/${subdomain}/${userId}`, {})
        .then((res) => {
            const {data} = res;
            return dispatch({type: OFFICE_CONTACT_SUCCESS, payload: data});
        })
        .catch((err) => {
            console.log("err wpeo2o3p", err);
            return dispatch({type: SHOW_USER_MESSAGE_ERROR});
        });
};

export const userFormDelete = (id) => (dispatch) => {
    dispatch({type: USERFORM_DELETE_LOADING});
    return executeDelete(`userforms/deleteuserform/${id}`)
        .then(() => {
            return dispatch({type: USERFORM_DELETE_SUCCESS});
        })
        .catch((err) => {
            console.log("err wpeo59gj", err);
            return dispatch({type: USERFORM_DELETE_ERROR});
        });
};

export const getTransaction = (subdomain) => (dispatch) => {
    dispatch({type: TRANSACTION_GET_LOADING});
    return executeGet(`transaction/all/${subdomain}`)
        .then((res) => {
            const {data} = res;
            const {transactionData} = data;
            return dispatch({
                type: TRANSACTION_GET_SUCCESS, payload: transactionData,
            });
        })
        .catch((err) => {
            console.log("err qqowqqpw", err);
            return dispatch({type: TRANSACTION_GET_ERROR});
        });
};

export const sendIntakeForm = (data) => (dispatch) => {
    dispatch({type: SEND_INTAKE_LOADING});
    return executePost(`SosCustomers/intakeSend`, data)
        .then((res) => {
            dispatch({type: SEND_INTAKE_SUCCESS});
            return true;
        })
        .catch((err) => {
            console.log("err qqpwojro", err);
            dispatch({type: SEND_INTAKE_ERROR});
            return false;
        });
};

export const getCurrentMessages = (subdomain) => (dispatch) => {
    dispatch({type: FETCH_CURRENT_MESSAGES_LOADING});
    return executeGet(`message/userCurrentMessage/${subdomain}`)
        .then((res) => {
            const {data} = res;
            const {message} = data;
            return dispatch({
                type: FETCH_CURRENT_MESSAGES_SUCCESS, payload: message,
            });
        })
        .catch((err) => {
            console.log("err qpwor939", err);
            return dispatch({type: FETCH_CURRENT_MESSAGES_ERROR});
        });
};

export const getLogo = (subdomain) => (dispatch) => {
    dispatch({type: FETCH_URL_LOADING});
    return executePost(`SosCustomers/logoUrl`, {subdomain})
        .then((res) => {
            const {data} = res;
            const {logoUrl, theme} = data;
            localStorage.setItem("logoUrl", logoUrl || "");
            localStorage.setItem("theme", JSON.stringify(theme || ""));
            return dispatch({
                type: FETCH_URL_SUCCESS, payload: data,
            });
        })
        .catch((err) => {
            console.log("err wpekgjti", err);
            return dispatch({type: FETCH_URL_ERROR});
        });
};

export const notificationRequestForm = (data) => (dispatch) => {
    dispatch({type: NOTIFICATION_INTAKEFORM_LOADING});
    return executePost(`SosCustomers/requestForm`, data)
        .then(() => {
            return dispatch({
                type: NOTIFICATION_INTAKEFORM_SUCCESS,
            });
        })
        .catch((err) => {
            console.log("err wpgjriei", err);
            return dispatch({type: NOTIFICATION_INTAKEFORM_ERROR});
        });
};

export const getUserForm = (userId, subdomain) => (dispatch) => {
    dispatch({type: GET_USERFORM_LOADING});
    return executeGet(`userforms/getcurrentuserform/${userId}/${subdomain}`)
        .then((res) => {
            const {data} = res;
            const {userForm} = data;
            return dispatch({
                type: GET_USERFORM_SUCCESS, payload: userForm,
            });
        })
        .catch((err) => {
            console.log("err wpgjrieo", err);
            return dispatch({type: GET_USERFORM_ERROR});
        });
};

export const sendMessageSuccessNotify = () => (dispatch) => {
    dispatch({type: SEND_MESSAGE_SUCCESS_NOTIFY});
};

export const isTxnVoidable = (transactionId) => {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_IS_VOIDABLE_LOADING, payload: transactionId,
        });
        executeGet(`Transaction/istxnvoidable/${transactionId}`, {})
            .then((res) => {
                const {data} = res || [];
                const {isTxnVoidable} = data;

                return dispatch({
                    type: TRANSACTION_IS_VOIDABLE_SUCCESS, payload: isTxnVoidable || false,
                });
            })
            .catch((err) => {
                console.log("err njdnfj32", err);
                return dispatch({type: TRANSACTION_IS_VOIDABLE_ERROR});
            });
    };
};

export const refundTxn = (transactionId, patients) => {
    return (dispatch) => {
        dispatch({type: TRANSACTION_REFUND_LOADING, payload: transactionId});
        executePost(`Transaction/refundtxn/${transactionId}`, {patientPayerRefundAmts: patients})
            .then((res) => {
                const {data} = res || [];
                return dispatch({
                    type: TRANSACTION_REFUND_SUCCESS, payload: data || [],
                });
            })
            .catch((err) => {
                console.log("err nansnej3", err);
                return dispatch({type: TRANSACTION_REFUND_ERROR});
            });
    };
};
export const updateIntakeFormType = (intakeFormType, intakeFormId) => {
    return (dispatch) => {
        dispatch({type: UPDATE_INTAKE_FORM_TYPE_LOADING});
        executePut(`SosCustomers/updateIntakeFormType/${intakeFormId}/${intakeFormType}`)
            .then((res) => {
                const {data} = res || [];
                if (data.message === "success") {
                    return dispatch({
                        type: UPDATE_INTAKE_FORM_TYPE_SUCCESS,
                    });
                } else {
                    console.log("err hsu3i2u3", err);
                    return dispatch({
                        type: UPDATE_INTAKE_FORM_TYPE_ERROR,
                        payload: data,
                    });
                }
            })
            .catch((err) => {
                console.log("err nansnej3", err);
                return dispatch({
                    type: UPDATE_INTAKE_FORM_TYPE_ERROR,
                    payload: err
                });
            });
    };
};
//
// export const deleteUploadedDoc = (data) => {
//     const {subdomain, type} = data;
//     let url = "";
//     if (type === "document") {
//         url = "SosCustomers/removeDoc";
//     } else if (type === "intakeform") {
//         url = "SosCustomers/removeIntakeForm";
//     } else {
//         throw new Error("Failed to remove");
//     }
//     return (dispatch) => {
//         dispatch({type: DELETE_UPLOADED_LOADING});
//         return executeDelete(url, {data})
//             .then((res) => {
//                 const {dataResponse} = res || {};
//                 if (data) {
//                     dispatch(getAdminUserData(subdomain));
//                     return dispatch({type: UPLOAD_FILE_SUCCESS, payload: dataResponse});
//                 }
//             })
//             .catch((err) => {
//                 console.log("err whti382o", err);
//                 dispatch({type: UPLOAD_FILE_ERROR});
//                 return err;
//             });
//     };
// };
