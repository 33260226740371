import React, {useEffect, useState} from "react";
import {Modal, Overlay, Popover} from "react-bootstrap";
import "./popover.css";
import {CrossIcon} from "../Icons";
import map from "lodash/map";
import {amountFormatterWithZero, subDomain} from "../../utils";
import sumBy from "lodash/sumBy";
import {withButtonLoader} from "../../helpers";

const CustomPopoverRefund = (
    {
        title = "",
        show,
        target,
        isVoidable,
        doRefund,
        close,
        setShowPopUp,
        isLoading,
        transaction,
        themeColor1
    }) => {

    const [patientPayerInfo, setPatientPayerInfo] = useState([]);
    const [code, setCode] = useState("");

    const subdomain = subDomain();

    useEffect(() => {
        if (transaction.patientPayer) {
            setPatientPayerInfo(transaction.patientPayer.map(o => {
                return {...o, amountRefunded: o.amountPaid};
            }));
        }

    }, [transaction]);

    const getLastFour = (txnRecord) => {
        if (txnRecord.transactionsData &&
            txnRecord.transactionsData.ssl_card_number &&
            txnRecord.transactionsData.ssl_card_number > "") {
            return txnRecord.transactionsData.ssl_card_number.slice(-4);

        } else if (txnRecord.transactionsData &&
            txnRecord.transactionsData.ssl_bank_account_number &&
            txnRecord.transactionsData.ssl_bank_account_number > "") {
            return txnRecord.transactionsData.ssl_bank_account_number.slice(-4);

        } else {
            return "";
        }
    }

    const formatValue = (e) => {
        if (e && e.target && e.target.value) {
            e.target.value = amountFormatterWithZero(e.target.value);
        }
    }

    const displayName = (patient) => {
        if (!patient.patientFirst || !patient.patientLast) {
            return <p>Anonymous</p>;
        }
        if (patient.patientFirst.length > 10 || patient.patientLast.length > 10) {
            return <p
                className="card-inner-text-font-payer payer-name-font">{patient && `${patient.patientFirst || ""} ${patient.patientLast}`}</p>;
        } else {
            return <p
                className="payer-name-font">{patient && `${patient.patientFirst || ""} ${patient.patientLast}`}</p>
        }
    }

    const onAmountChangeHandler = (e, index) => {
        let patients = [...patientPayerInfo];
        const value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(".", "x")
            .replace(/\./g, "")
            .replace("x", ".");
        if (patients[index]) {
            patients[index].amountRefunded = value;
        }
        setPatientPayerInfo(patients);
    };

    const handleClick = (e) => {
        e.target.select();
    };

    const getPayerAmount = (index) => {
        if (!patientPayerInfo) {
            return null;
        }
        if (patientPayerInfo.length === 0) {
            return null;
        }
        return patientPayerInfo[index].amountRefunded;
    }

    const totalAmountsPaid = () => {
        return sumBy(map(transaction.patientPayer, (i) => ({
            ...i, amountPaid: parseFloat(i.amountPaid) || 0,
        })), "amountPaid");
    };

    const totalAmountsRefund = () => {
        let total = 0;
        for (let patient of patientPayerInfo) {
            total += parseFloat(patient.amountRefunded);
        }
        return total;
    };

    const refund = () => {
        if (subdomain === 'pathwaysfamilywellness' || subdomain === 'testguanabana' ) {
            if (code.toLowerCase() !== 'athena') {
                alert('Incorrect authorization code');
                return;
            }
        }

        if (isVoidable === false) {
            if (totalAmountsRefund() > totalAmountsPaid()) {
                return;
            }
        }
        doRefund(transaction._id, patientPayerInfo);
    };

    return (
        <>
            <div>
                <Modal show={show} size="md" centered className="update-successfully">
                    <div data-cy="modal-pop-up" onClick={() => setShowPopUp(false)} className="close-modal-btn">
                        <CrossIcon/>
                    </div>
                    <Modal.Body>
                        <div className="modal-headr-text">
                            <h2>{title}</h2>
                            {isVoidable ? (<>
                                <p>{`Are you sure you want to void this transaction in the amount of \$${transaction.total}
                             on payment method ${transaction.transactionsData.ssl_card_type === "CREDITCARD" ? transaction.transactionsData.ssl_card_short_description :
                                    'eCheck'} (...${getLastFour(transaction)})`}</p>
                                <div className="divider"/>
                                {(subdomain === 'pathwaysfamilywellness' || subdomain === 'testguanabana') && <div>
                                    <div
                                        className="d-flex flex-space-between">
                                        <p style={{width: '200px', textAlign: 'left'}}>Admin code</p>
                                        <input
                                            style={{width: '200px'}}
                                            type="text"
                                            className="pay-control custom-input-border-css"
                                            placeholder="code"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}/>
                                    </div>
                                    <div className="divider"/>
                                </div>}</>) : (<div>
                                <div>

                                    <div>
                                        <div>
                                            <div
                                                className="d-flex align-items-center pay-large-inner justify-content-between">
                                                <p style={{width: '150px'}}></p>
                                                <p
                                                    className="pay-account-balance"
                                                    style={{
                                                        paddingLeft: 0,
                                                        fontWeight: "normal",
                                                        width: '100px',
                                                        textAlign: 'left'
                                                    }}
                                                >Amount Paid</p>
                                                <p style={{width: '200px', textAlign: 'left'}}>Amount To Refund</p>


                                            </div>
                                        </div>
                                        {map(transaction.patientPayer, (a, index) => {
                                            return (
                                                <span key={index + a.patientPayerId + parseInt(a.amountPaid)}>
                                                    <div
                                                        className="d-flex align-items-center pay-large-inner justify-content-between">
                                                 <div style={{width: '150px', textAlign: 'left'}}>
                             {displayName(a)}
                          </div>
                        <p
                            className="pay-account-balance"
                            style={{
                                paddingLeft: 0, fontWeight: "normal", width: '100px',
                                textAlign: 'left'
                            }}
                        >{a && `$${amountFormatterWithZero(a.amountPaid) || ""}`}</p>
                        <input
                            style={{width: '200px'}}
                            type="text"
                            className="pay-control custom-input-border-css"
                            id={`accountbalance-${index}`}
                            aria-describedby="emailHelp"
                            placeholder="$0.00"
                            onBlur={(e) => formatValue(e)}
                            value={getPayerAmount(index)}
                            onChange={(e) => onAmountChangeHandler(e, index)}
                            onClick={(e) => handleClick(e)}
                        />
                      </div>
                      <div className="divider"/>
                                                    {(subdomain === 'pathwaysfamilywellness' || subdomain === 'testguanabana') && <div>
                                                        <div
                                                            className="d-flex flex-space-between">
                                                            <p style={{width: '200px', textAlign: 'left'}}>Admin code</p>
                                                            <input
                                                                style={{width: '200px'}}
                                                                type="text"
                                                                className="pay-control custom-input-border-css"
                                                                placeholder="code"
                                                                value={code}
                                                                onChange={(e) => setCode(e.target.value)}/>
                                                        </div>
                                                        <div className="divider"/>
                                                    </div>}
                    </span>);
                                        })}
                                        <div className="d-flex hommeexisting-large-inner-text flex-space-between">
                                            <p>Total</p>
                                            <p style={{paddingRight: '0px'}}>${amountFormatterWithZero(totalAmountsRefund())}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>)}
                            <div className="flex flex-align-center flex-end">
                                <button
                                    className="custom-popover-button  sos-custom-btn-css"
                                    onClick={close}>
                                    Cancel
                                </button>

                                <button
                                    className="custom-popover-button close-btn sos-custom-btn-css m-l-10"
                                    onClick={() => refund()}>
                                    {withButtonLoader(
                                        "Refund",
                                        isLoading === true,
                                        themeColor1)
                                    }
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default CustomPopoverRefund;
