const errors = {
    'SERV NOT ALLOWED': 'Your card is not supported',
    // "RESTRICTED CARD":"",
    // "DECLINED:BLOCKED":"",
    // "SUSPECTED FRAUD": "",
    // "DECLINED 82": "",
    // "PICK UP CARD": "",
    // "DECLINED": "",
    // "INVALID CAM\\CVV": "",
    // "DECLINED: NSF": "",
    // "TRAN NOT ALLOWED": "",
    // "DECLINED:CLOSED": "",
    // "APPROVAL": "",
    // "EXPIRED CARD": "",
    // "DECLINED 79": "",
    // "SERV NOT ALLOWED": "",
};


const convergeIssuerResponseMap = {
    "04": "Pick Up Card",
    "07": "Pick Up Card, (other than lost stolen)",
    "12": "Invalid Transaction",
    "14": "Invalid Account Number",
    "15": "No Such Issuer",
    "41": "Pick Up Card (Lost Card)",
    "43": "Pick Up Card (Stolen Card)",
    "46": "Closed Account",
    "57": "Transaction Not Permitted to Cardholder",
    "R0": "Stop Payment Order",
    "R1": "Revocation of Authorization Order",
    "R3": "Revocation of All Authorizations Order",
    "03": "Invalid Merchant",
    "19": "Re-enter Transaction",
    "51": "Not Sufficient Funds (NSF)",
    "59": "Suspected Fraud",
    "61": "Exceeds Approval Amount Limit",
    "62": "Restricted Card",
    "65": "Exceeds Withdrawal Frequency Limit",
    "75": "Exceeds Allowable Number of PIN Entries",
    "78": "Transaction from new cardholder, and card not properly unblocked",
    "86": "Cannot Verify PIN",
    "91": "Issuer or Switch Inoperative",
    "93": "Transaction could not be completed - Violation of Law",
    "96": "System Malfunction",
    "N3": "Cash Service Not Available",
    "N4": "Cashback request exceeds Issuer limit",
    "54": "Expired Card",
    "55": "Incorrect or Missing PIN",
    "82": "Invalid CAM or CVV; Negative Online CAM, dCVV, iCVV or CVV results Or Offline PIN authentication interrupted or Cashback limit exceeded",
    "N7": "Decline for CVV2 Failure"
};


export const showPaymentError = (err) => {
    debugger;
    let error = '';
    if (typeof err === 'object') {
        error = [err.errorCode, err.errorName, err.errorMessage];
        let errorMessage = error.join(' ').trim();

        let unsuccessfulTxnMsg = `Transaction unsuccessful. Please check your payment information or use a different payment method.
                ${errorMessage ? `\n` + errorMessage : ""}
                ${err && err.ssl_result_message ? `\n` + err.ssl_result_message + ": " + (errors[err.ssl_result_message] || "") : ""}
                ${err && err.ssl_issuer_response ? `\n` + err.ssl_issuer_response + ": " + (convergeIssuerResponseMap[err.ssl_issuer_response] || "") : ""}
                ${err && err.ssl_avs_response && err.ssl_avs_response !== " " ? `\n` + "AVS Response: " + err.ssl_avs_response : ""}
            `;

        return unsuccessfulTxnMsg

    } else if (typeof err === 'string') {
        if (err.includes("java.lang")) {
            console.log("error #029z9292");
            console.log(err);
            return `Transaction unsuccessful. Please check your payment information or use a different payment method.`
        }
        return err;
    }
}
